<template>
  <vs-row vs-justify="flex-end">
    <vs-button color="primary" class="mb-2" type="flat" style="color: #82868B" @click="resetFilters">Reset
      Filters</vs-button>
    <vs-row class="filter-container">
      <!-- Date Range -->
      <div
        class="select-date-range"
        v-if="!['clinics', 'nurses'].includes(page)"
      >
        <span>Select Date Range</span>
        <Datepicker
          range
          v-model="selected.selectedDate"
          lang="en"
        />
      </div>

      <!-- Organization -->
      <div class="flex items-center justify-end" 
        v-if="(['SuperAdmin', 'Supplier'].includes(urlType))">
        <vs-select
          v-model="selected.org"
          placeholder="Select Org"
          :label="'Select Org'" 
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsOrgs"
          />
        </vs-select>
        <vs-icon v-if="selected.org.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.org = []"></vs-icon>
      </div>

      <!-- States -->
      <div class="flex items-center justify-end">
        <vs-select
          v-model="selected.state"
          placeholder="Select States"
          :label="isAge ? 'State' : 'Select State'" 
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.value"
            v-for="(item, index) in optionsStates"
          />
        </vs-select>
        <vs-icon v-if="selected.state.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.state = []"></vs-icon>
      </div>

      <!-- Postcodes -->
      <div class="flex items-center justify-end">
        <vs-select
          v-model="selected.postcode"
          placeholder="Select Postcodes"
          :label="isAge ? 'Patient Postcodes' : 'Select Postcodes'" 
          autocomplete
          multiple
          @input-change="handleSearchPostcode"
          class="m-1"
          :disabled="!selected.state.length"
          @blur="blur('postcode')"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsPostCodes"
          />
        </vs-select>
        <vs-icon v-if="selected.postcode.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.postcode = []"></vs-icon>
      </div>

      <!-- Suburb -->
      <div class="flex items-center justify-end">
        <vs-select
          v-model="selected.suburb"
          placeholder="Select Suburbs"
          :label="isAge ? 'Patient Suburbs' : 'Select Suburbs'"
          autocomplete
          multiple
          @input-change="handleSearchSuburb"
          class="m-1"
          :disabled="!selected.state.length"
          @blur="blur('suburb')"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsSuburbs"
          />
        </vs-select>
        <vs-icon v-if="selected.suburb.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.suburb = []"></vs-icon>
      </div>

      <!-- Areas -->
      <div v-if="['percent-of-treatment'].includes(page)" class="flex items-center justify-end">
        <vs-select
          v-model="selected.area"
          placeholder="Select Area"
          label="Select Area"
          autocomplete
          multiple
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsArea"
          />
        </vs-select>
        <vs-icon v-if="selected.area.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.area = []"></vs-icon>
      </div>

      <!-- Clinics -->
      <div v-if="!isAllClinics" class="flex items-center justify-end">
        <vs-select
          v-model="selected.clinic"
          placeholder="Select Clinics"
          label="Select Clinics"
          autocomplete
          multiple
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsClinics"
          />
        </vs-select>
        <vs-icon v-if="selected.clinic.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.clinic = []"></vs-icon>
      </div>

      <!-- Frequency -->
        <!-- <vs-select
          v-model="selected.frequency"
          placeholder="Select Frequency"
          label="Select Frequency"
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsFrequency"
          />
        </vs-select> -->       
      <!-- </div> -->

      <!-- Products -->
      <div
        class="flex items-center justify-end"
        v-if="!['single-vs-multiple-treatment', 'treatment-areas-by-brand', 'percent-of-treatment', 'brand-ml', 'brand-unit', 'nurses', 'clinics', 'patients'].includes(page)">
        <vs-select
          v-model="selected.product"
          placeholder="Select Products"
          label="Select Products"
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsProducts"
          />
        </vs-select>
        <vs-icon v-if="selected.product.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.product = []"></vs-icon>
      </div>

      <!-- Brands -->
      <div
        class="flex items-center justify-end"
        v-if="!['single-vs-multiple-treatment', 'treatment-areas-by-brand', 'brand-ml', 'percent-of-treatment','brand-unit', 'nurses', 'clinics', 'patients'].includes(page) || isSupplierOrAdminAndOvertimeTab()">
        <vs-select
          v-model="selected.brand"
          placeholder="Select Brands"
          label="Select Brands"
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsBrands"
          />
        </vs-select>
        <vs-icon v-if="selected.brand.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.brand = []"></vs-icon>
      </div>

      <!-- Quintile -->
      <div v-if="['single-vs-multiple-treatment'].includes(page)">
        <vs-select
          v-model="selected.quintile"
          placeholder="Select Quintile"
          label="Select Quintile"
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsQuintile"
          />
        </vs-select>
      </div>

      <!-- Age 
        *** 'brand-ml', should be included, but not in fact table summary
      -->
      <div
        class="flex items-center justify-end"
        v-if="['treatment-areas-by-brand', 'brand-unit'].includes(page)">
        <vs-select
          v-model="selected.age"
          placeholder="Select Age"
          label="Select Age"
          autocomplete
          class="m-1"
          multiple
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsAge"
          />
        </vs-select>
        <vs-icon v-if="selected.age.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.age = []"></vs-icon>
      </div>

      <!-- Search Nurse -->
      <div class="flex items-center justify-end" v-if="isOrgLoggedIn || isSupplierOrAdminAndOvertimeTab()">
        <vs-select
          v-model="selected.nurse_id"
          placeholder="Search Nurse"
          label="Search Nurse"
          autocomplete
          class="m-1"
          multiple
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsNurse"
          />
        </vs-select>
        <vs-icon v-if="selected.nurse_id.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.nurse_id = []"></vs-icon>
      </div>

      <!-- Number of Days Filter -->
      <div v-if="['clinics', 'nurses'].includes(page)">
        <vs-select
          v-model="selected.number_of_days"
          placeholder="Select Number of Days"
          label="Select Number of Days"
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsNumberOfDays"
          />
        </vs-select>
      </div>

    </vs-row>
  </vs-row>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { compact } from "lodash"
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';

export default {
  components: {
    Datepicker: VueDatepickerUi
  },
  props: {
    selected: {
      type: Object,
    },
    page: {
      type: String,
      default: "number-of-treatment-per-treatment-area"
    },
    isOvertime: {
      type: Boolean,
      default: false
    },
    urlType: String,
    isAllClinics: {
      type: Boolean,
      default: false
    },
  },
  inject: {
    isAge: { default: false }
  },
  computed: {
    ...mapGetters("analytics", ["DEFAULT_STATE_FILTER"]),
    ...mapState("analytics", ["filters"]),
    optionsPostCodes() {
      return this.filters.postcodes.map(postcodes => (
        { value: postcodes.value, label: postcodes.text }
      ))
    },
    optionsSuburbs() {
      return this.filters.suburbs.map(suburbs => (
        { value: suburbs.value, label: suburbs.text }
      ))
    },
    optionsBrands() {
      return this.filters.brands.map(brands => (
        { value: brands.value, label: brands.text }
      ))
    },
    optionsProducts() {
      return this.filters.products.map(products => (
        { value: products.value, label: products.text }
      ))
    },
    optionsOrgs() {
      return this.filters.orgs.map(orgs => (
        { value: orgs.value, label: orgs.text }
      ))
    },
    optionsStates() {
      return this.filters.states.map(states => (
        { value: states.value, label: states.text }
      ))
    },
    optionsClinics() {
      return this.filters.clinics.map(clinics => (
        { value: clinics.value, label: clinics.text }
      ))
    },
    optionsArea() {
      return this.filters.area.map(area => (
        { value: area.value, label: area.text }
      ))
    },
    optionsQuintile() {
      return this.filters.quintile.map(quintile => (
        { value: quintile.value, label: quintile.text }
      ))
    },
    optionsAge() {
      return this.filters.age.map(age => (
        { value: age.value, label: age.text }
      ))
    },
    optionsFrequency() {
      return this.filters.frequency.map(freq => (
        { value: freq.value, label: freq.text }
      ))
    },
    optionsNurse() {
      return this.filters.nurses.map(nurse => (
        { value: nurse.value, label: nurse.text }
      ))
    },
    isOrgLoggedIn() {
      return !!localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`) &&
        [
          'average-treatment-by-area-filler', 'average-treatment-by-age-filler',
          'average-treatment-by-gender-and-age-filler', 'average-treatment-by-area-toxin',
          'average-treatment-by-age-toxin', 'average-treatment-by-gender-and-age-toxin',
          'treatment-areas-per-treatment-area-filler-nurse', 'treatment-areas-per-treatment-area-toxin-nurse'
        ].includes(this.page)
    },
    optionsNumberOfDays() {
      return this.filters.number_of_days.map(number_of_days => (
        { value: number_of_days.value, label: number_of_days.text }
      ))
    },
  },
  data() {
    return {
      selectedData: {},
      postcode: {
        isLoading: false,
      },
      suburb: {
        isLoading: false,
      },
      timer: null,
      isFetching: false,
      triggerFilter: ''
    }
  },
  methods: {
    ...mapActions("analytics", [
      "fetchFilterByState", "fetchAnalyticsFiltersSearch", "fetchAnalyticsFilters",
      "fetchAnalyticsFiltersSearchClinic", "fetchClinicState", "fetchAnalyticsFiltersSearchClinicNurse"
    ]),
    ...mapMutations("analytics", ["MUTATE_FILTERS"]),
    resetFilters() {
      this.selected.brand = [];
      this.selected.postcode = [];
      this.selected.suburb = [];
      this.selected.product = [];
      this.selected.org = [];
      this.selected.state = [];
      this.selected.clinic = [];
      this.selected.frequency = 30;
      this.selected.quintile = [0];
      this.selected.age = [];
      this.selected.area = [];
      this.selected.nurse_id = [];
      this.selected.number_of_days = [];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    isSupplierOrAdminAndOvertimeTab(){
      const isSupplier = this.urlType === 'SuperAdmin' || this.urlType === 'Supplier'
      if(this.isOvertime && isSupplier) return true
    },
    handleSearchArea(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'areas',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchClinic(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          const params = {
            type: 'clinics',
            search: input.target.value,
            states: this.selectedData.state
          }
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          if (org_id) params['org_id'] = org_id

          await this.fetchAnalyticsFiltersSearch(params)
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchBrand(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'brands',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchProduct(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'products',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchNurse(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          const params = {
            type: 'nurses',
            search: input.target.value,
          }
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          if (org_id) params['org_id'] = org_id

          await this.fetchAnalyticsFiltersSearch(params)
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchPostcode(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchFilterByState({
            type: 'search',
            params: {
              search: input.target.value,
              postCode: true,
              prereq_value: this.selectedData.state
            }
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchSuburb(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchFilterByState({
            type: 'search',
            params: {
              search: input.target.value,
              suburb: true,
              prereq_value: this.selectedData.postcode,
              prereq_value_2: this.selectedData.state
            }
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    showLoading(container) {
      this.$vs.loading({
        color: 'primary',
        container,
        scale: 0.6
      })
    },
    closeLoading(container) {
      this.$vs.loading.close(container)
    },
    blur(filter) {
      const relatedToStates = ['postcode', 'suburb']

      if (relatedToStates.includes(filter)) {
        let params = {
          search: '',
          suburb: true,
          prereq_value: this.selectedData.postcode,
          prereq_value_2: this.selectedData.state
        }

        if (filter === 'postcode') {
          params = {
            search: '',
            postCode: true,
            prereq_value: this.selectedData.state
          }
        }

        this.fetchFilterByState({
          type: 'search',
          params
        })
      } else {
        const params = {
          type: filter,
          search: '',
        }

        if (filter === 'clinics') {
          params['states'] = this.selectedData.state
        }

        this.fetchAnalyticsFiltersSearch(params)
      }
    },
    refreshFilters() {
      let payload = {}
      const org_id = [localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)]

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) payload.org_id = [this.orgId]

      if(this.urlType === "Supplier" && this.userbrands) payload.brand_id = this.userbrands
      this.$vs.loading();
      this.fetchAnalyticsFilters(payload).then(() => this.$vs.loading.close()
      ).catch((err) => {
        console.error("##ERROR: ", err.message)
        this.$vs.loading.close();
      });
    },
    getUserOrgId(){
      try{
        return localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
      } catch(error){
        // Handle exception
      }
    },
  },
  watch: {
    selected: {
      handler(newVal) {
        this.selectedData = newVal;
      },
      immediate: true,
    },
    selectedData: {
      handler(newVal) {
        this.$emit("updateFilters", newVal);
      },
      deep: true,
    },
    isAllClinics: {
      handler(newVal) {
        if(newVal) {
          this.selectedData.clinic = []
        }
      }
    },
    'selectedData.state': {
      handler() {
        if (this.triggerFilter === '' || this.triggerFilter === 'state') {
          const params = {
            state_abbreviation: this.selectedData.state,
            org_id: this.selectedData.org,
          }
          if (this.urlType == "OrgOwner") {
            params['org_id'] = [this.orgId];
          }

          try{
            let userType = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
            if (userType && userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()) {
              const brands = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).brands;
              params['brand_id'] = brands
            }
          } catch (error){
            // No organization/role
          }

          this.fetchAnalyticsFiltersSearchClinic(params)
        }

        if (this.selectedData.state && this.selectedData.state.length) {
          this.postcode.isLoading = true
          this.suburb.isLoading = true

          if (this.triggerFilter === '') {
            this.triggerFilter = 'state'
          }

          this.fetchFilterByState({
            type: 'both',
            params: {
              state: this.selectedData.state
            }
          }).then(() => {
            this.postcode.isLoading = false
            this.suburb.isLoading = false
          }).catch(() => {
            this.postcode.isLoading = false
            this.suburb.isLoading = false
          })
        } else {
          if (this.triggerFilter === 'state') {
            this.triggerFilter = ''
          }

          this.MUTATE_FILTERS({
            data: {
              from: 'reset',
              postCode: [],
              suburb: []
            }
          })
          this.selectedData.postcode = []
          this.selectedData.suburb = []
        }
      },
      deep: true
    },
    'selectedData.postcode': {
      handler() {
        if (this.selectedData.postcode && this.selectedData.postcode.length) {
          this.suburb.isLoading = true

          this.fetchFilterByState({
            type: 'postCode',
            params: {
              postCode: this.selectedData.postcode
            }
          }).then(() => this.suburb.isLoading = false
          ).catch(() => this.suburb.isLoading = false)
        } else {
          this.MUTATE_FILTERS({
            data: {
              from: 'reset',
              suburb: []
            }
          })
          this.selectedData.suburb = []
        }
      },
      deep: true
    },
    'selectedData.clinic': {
      async handler() {
        if (this.triggerFilter === '' || this.triggerFilter === 'clinic') {
          const result = await this.fetchClinicState({
            clinic_id: this.selectedData.clinic,
            org_id: this.selectedData.org,
          })

          if (result.data) {
            const { data } = result.data.clinics
            let states = compact(this.DEFAULT_STATE_FILTER.map((state) => {
              const matched = data.find((currState) => currState.state === state.text || currState.state === state.value)
              if (matched) return state
              return undefined
            }))

            if (!data.length) {
              states = this.DEFAULT_STATE_FILTER
            }

            this.MUTATE_FILTERS({
              data: {
                states
              }
            })
          }

          const params = {
            clinic_id: this.selectedData.clinic,
            org_id: this.selectedData.org,
            to: 'nurse'
          }
          if (this.urlType == "OrgOwner") {
            params['org_id'] = [this.orgId];
          }

          try{
            let userType = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
            if (userType && userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()) {
              const brands = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).brands;
              params['brand_id'] = brands
            }
          } catch (error){
            // No organization/role
          }
          
          this.fetchAnalyticsFiltersSearchClinicNurse(params)
        }

        if (this.selectedData.clinic && this.selectedData.clinic.length) {
          if (this.triggerFilter === '') {
            this.triggerFilter = 'clinic'
          }
          return
        }

        if (this.triggerFilter === 'clinic') {
          this.triggerFilter = ''
        }
      },
      deep: true
    },
    'selectedData.nurse_id': {
      async handler() {
        if (this.triggerFilter === '' || this.triggerFilter === 'nurse') {
          const params = {
            nurse_id: this.selectedData.nurse_id,
            org_id: this.selectedData.org,
            to: 'clinic'
          }
          if (this.urlType == "OrgOwner") {
            params['org_id'] = [this.orgId];
          }
          try{
            let userType = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
            if (userType && userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()) {
              const brands = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).brands;
              params['brand_id'] = brands
            }
          } catch (error){
            // No organization/role
          }

          this.fetchAnalyticsFiltersSearchClinicNurse(params)
        }

        if (this.selectedData.nurse_id && this.selectedData.nurse_id.length) {
          if (this.triggerFilter === '') {
            this.triggerFilter = 'nurse'
          }
          return
        }

        if (this.triggerFilter === 'nurse') {
          this.triggerFilter = ''
        }
      },
      deep: true
    },
    'selectedData.org': {
      async handler() {
        const params = {
          org_id: this.selectedData.org,
          brand_id: this.selected.brand,
        }
        if (this.urlType == "OrgOwner") {
          params['org_id'] = [this.orgId];
        }
        try{
          let userType = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
          if (userType && userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()) {
            const brands = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).brands;
            params['brand_id'] = brands
          }
        } catch (error){
          // No organization/role
        }
        
        this.fetchAnalyticsFilters(params);
      },
      deep: true
    },
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
      this.userbrands = userType.brands
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.supplierCurrentBrands = userType.brands;
    }
    this.refreshFilters();
  },
}
</script>

<style lang="css">
.filter-container>.con-select {
  width: 120px;
}

.con-select .vs-select--input,
.vs-select--options span {
  font-size: .85rem;
}

.removeSelectedFilters{
  position: absolute;
  margin-right: 18px;
  margin-top: 16px;
  cursor: pointer;
}
.select-date-range{
  margin:.35rem 0 0 .35rem;
}
.v-calendar .input-field input{
  height: 34.4px;
}
.select-date-range > span {
  font-size: .85rem;
  color: rgba(0,0,0,.6);
}

.v-calendar .content {
  top: 35px !important;
}
</style>


<template>
  <div class="flex flex-col gap-2">
    <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
      <TabInsights
        :title="`Average Treatment By Age (${treatmentType.toUpperCase()})`"
        toolTiptext="What can I learn from this chart?"
        :hasQuickInsights="false"
      >
        <AverageTreatmentByAge
          :type="treatmentType"
          :selected="selected"
          :chartDataProps="chartData"
          @updateChartData="chartData = $event"
          :isAllClinicsProps="isAllClinics"
          @updateIsAllClinics="isAllClinics = $event"
          :urlType="urlType"
        />
      </TabInsights>
    </vs-row>

    <!-- <div
      class="flex mt-2" style="justify-content: flex-end;">
      <vs-button style="padding:5px 15px;" @click="handleExportToCSV">
        Export
      </vs-button>
    </div> -->
    <vs-table
      v-if="chartData && chartData.labels"
      max-items="10"
      pagination
      noDataText="No Data Available"
      :data="chartData.labels"
    >
      <template slot="thead">
        <vs-th
          style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 50vw;
            max-width: 50vw;
          "
          >AGE BRACKET</vs-th
        >
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >AVERAGE UNIT PER TREATMENT</vs-th
        >
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr">
            {{ tr }}
          </vs-td>
          <vs-td :data="tr">
            {{ `${getTotalTreatments(tr).toFixed(2)} unit` }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AverageTreatmentByAge from "../../components/insights/treatments/AverageTreatmentByAge.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from '@/assets/utils'
import moment from 'moment';

export default {
  components: {
    AverageTreatmentByAge,
    TabInsights,
    Sidebar,
  },
  data() {
    return {
      selected: {
        postcode: [],
        suburb: [],
        brand: [],
        product: [],
        clinic: [],
        org: [],
        state: [],
        nurse: [],
        frequency: 30,
      },
      chartData: {},
      treatmentType: "",
      urlType: "",
      orgId: "",
      isAllClinics: false,
    };
  },
  methods: {
    ...mapActions("analytics", ["fetchTreatmentAreasAge"]),
    getTreatmentAreasAge() {
      this.$vs.loading();
      let payload = {
        treatment_product_id: this.selected.product,
        postcode: this.selected.postcode,
        frequency: this.selected.frequency,
        brand_id: this.selected.brand,
        state_abbreviation: this.selected.state,
        suburb_name: this.selected.suburb,
        clinic_id: this.selected.clinic,
        type: this.treatmentType,
        is_age: true,
        org_id: this.selected.org,
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org_id: [this.orgId],
          nurse_id: this.selected.nurse
        }
      }
      this.fetchTreatmentAreasAge(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("#ERR: ", err.message);
          this.$vs.loading.close();
        });
    },
    getTotalTreatments(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = `avg_dose(mL)_per_treatment_by_age(${this.treatmentType})_`
      csv += "AGE BRACKET, AVERAGE UNIT PER TREATMENT\n";

      this.chartData.labels.forEach(row => {
        csv += `${row},${this.getTotalTreatments(row).toFixed(2)} unit\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
    }
  },
  watch: {
    $route: {
      handler(newVal){
        if([
          "SuperAdminAverageTreatmentByAgeFiller", 
          "OrgOwnerAverageTreatmentByAgeFiller",
          "SupplierAverageTreatmentByAgeFiller"
        ].includes(newVal.name)){
          this.treatmentType = "filler";
        }else {
          this.treatmentType = "toxin";
        }
        setTimeout(() => {
          this.getTreatmentAreasAge();
        }, 250);
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.getTreatmentAreasAge();
      },
      deep: true,
    },
    isAllClinics: {
      handler() {
        this.getTreatmentAreasAge();
      }
    }
  },
  async created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.selected.brand = userType.brands;
    }
    // this.getTreatmentAreasAge();
  },
};
</script>
